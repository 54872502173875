
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import { saveAs } from "@/api/request/base";
import { giftStockStatistics, giftStockStatisticsExcel } from "@/api/request/item";

//组件
@Component({
  name: "GiftStatistics",
})

//界面函数
export default class extends Vue {
  //请求数据
  private listQuery: any = {
    excel: false,
  };

  //定义变量
  private list: any[] = []; //数据
  private listLoading: boolean = true; // 列表加载

  //创建调用
  created() {
    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = false;

    //获取数据
    const { data } = await giftStockStatistics(this.listQuery);

    //数据赋值
    this.list = data;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //导出处理
  private async exportHandler() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.excel = true;

    //获取数据
    const { data } = await giftStockStatisticsExcel(this.listQuery);

    //保存数据
    saveAs(data, "道具存量统计");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }
}
